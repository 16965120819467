$(() => {
    scrollDownIcon()
    menuItemIntersection()
    closeSidebarOnLinkClick()
    $(window).on('scroll', iconOpacity);

    const glightbox = GLightbox();

    // scroll down icon
    function scrollDownIcon() {
        $('.floating-icon svg').on('click', () => {
            let nextSection = $('#quem-somos');
        
            $('html, body').animate({
                scrollTop: nextSection.offset().top
            }, 100);
        })
    }

    // whatsapp floating btn opacity
    function iconOpacity() {
        if ( window.scrollY >= 200 ) {
            $('#whatsapp').css({ 
                opacity: '1',
                'z-index': '10'
            });
        } else {
            $('#whatsapp').css({ 
                opacity: '0',
                'z-index': '-1'
            });
        }
    }

    // observe active section to active menu item
    function menuItemIntersection() {
        const sections = document.querySelectorAll('section');
        const navLinks = document.querySelectorAll('.nav-link');
      
        function updateActiveLink(targetId) {
            navLinks.forEach(link => {
                const href = link.getAttribute('href');

                if (href === `#${targetId}`) {
                    link.classList.add('active');
                } else {
                    link.classList.remove('active');
                }
            });
        }
      
        // Configurações do IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.getAttribute('id');

                    updateActiveLink(sectionId);
                }
            });
        }, { threshold: 0.2 });
      
        sections.forEach(section => {
          observer.observe(section);
        });
    }

    function closeSidebarOnLinkClick() {
        const offcanvasElement = document.querySelector('.offcanvas');
        const offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
        const navLinks = document.querySelectorAll('.nav-link');
    
        navLinks.forEach(link => {
            link.addEventListener('click', () => {

                if (offcanvasInstance) {
                    offcanvasInstance.hide();
                }
            });
        });
        $(".navbar .main-btn").on('click', () => {
            if (offcanvasInstance) {
                offcanvasInstance.hide();
            }
        })
    }
    

    // swipers
    const swiperHero = new Swiper('.swiper-hero', {
        slidesPerView: 1,
        spaceBetween: 12,
        loop: true,
        speed: 2000,
        lazyPreloadPrevNext: 1,
        autoplay: {
            delay: 9000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    })

    const swiperCatalog = new Swiper('.swiper-catalog', {
        slidesPerView: 1,
        spaceBetween: 15,
        grabCursor: true,
        loop: false,
        speed: 2000,
        lazyPreloadPrevNext: 1,
        pagination: {
            el: ".swiper-pagination-2",
            clickable: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
            },
            1460: {
                slidesPerView: 3
            }
        }
    })

    const swiperPromos = new Swiper('.swiper-promos', {
        slidesPerView: 1,
        spaceBetween: 12,
        loop: true,
        speed: 2000,
        lazyPreloadPrevNext: 1,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination-3",
            clickable: true,
        },
    })

    const swiperGallery = new Swiper('.swiper-gallery', {
        slidesPerView: 1,
        spaceBetween: 12,
        loop: true,
        speed: 2000,
        lazyPreloadPrevNext: 1,
        pagination: {
            el: ".swiper-pagination-4",
            clickable: true,
        },
    })
})